<template>
  <div>
    <div class="centered-loading-wrapper column" v-if="loading">
      <LoadingSpinner />
      <p>{{ actionName }} {{ selectedItemEdit?.name }}...</p>
    </div>
    <div v-else>
      <div class="fixed-header column">
        <BackToPageLink title="Back To Item List" @close="navigateTo" />
        <div class="justify-space-between row">
          <div class="name row">
            <Icon :path="mdiFood" />
            <label for="name">
              Item Name
              <span class="required-sign">*</span>
            </label>
            <input
              type="text"
              class="name"
              name="name"
              placeholder="Enter Item Name"
              v-model="selectedItemEdit.name"
              @input="setUnsavedChanges(true)" />
          </div>
          <div class="actions row">
            <button
              class="text-icon contained save-btn"
              @click="saveItem()"
              :disabled="!unsavedChanges || saving">
              <span class="icon">
                <Icon :path="mdiCheck" />
              </span>
              <span class="text">Save</span>
              <LoadingSpinner v-if="saving" />
            </button>
            <!-- <button
              class="text-icon contained save-btn"
              @click="saveAsNewItem()"
              :disabled="!unsavedChanges"
              v-if="fromMenuEditor">
              <span class="icon">
                <span class="material-icons material-icons-round">
                  &#xE5D0;
                </span>
              </span>
              <span class="text">Save As New</span>
              <LoadingSpinner v-if="saving" />
            </button> -->
          </div>
        </div>
      </div>

      <div class="content-wrapper">
        <div class="content">
          <div class="input-wrapper row baseline no-wrap">
            <label for="description">Description</label>
            <textarea
              name=""
              id=""
              cols="10"
              rows="3"
              v-model="selectedItemEdit.description"
              placeholder="Add a description for the menu item"
              @input="setUnsavedChanges(true)"></textarea>
          </div>
          <div class="detail-table">
            <div class="input-wrapper row">
              <label for="price"
                >Price <span class="required-sign">*</span></label
              >
              <div>
                <input
                  type="number"
                  name="price"
                  min="0"
                  v-model="selectedItemEdit.price"
                  @input="setUnsavedChanges(true)" />
              </div>
            </div>

            <div v-if="!!taxRates.length" class="input-wrapper row">
              <label for="taxRate">VAT Bracket</label>

              <select
                class="complexity"
                name="taxRate"
                id=""
                v-model="selectedItemEdit.taxRateId"
                @change="setUnsavedChanges(true)">
                <option v-for="(rate, i) in taxRates" :key="i" :value="rate.id">
                  {{ rate.name }}
                </option>
              </select>
            </div>

            <div class="input-wrapper row">
              <label for="complexity">Complexity</label>

              <select
                class="complexity"
                name="complexity"
                id=""
                v-model="selectedItemEdit.complexity"
                @change="setUnsavedChanges(true)">
                <option value="0">None</option>
                <option value="1">Low</option>
                <option value="2">Medium</option>
                <option value="3">High</option>
              </select>
            </div>

            <div class="row">
              <div class="allergens">
                <div class="input-wrapper">
                  <label for="allergens">Allergens</label>
                  <div class="input-wrapper_options row">
                    <div
                      class="checkbox-wrapper-unit"
                      :class="{ over18: allergen.name === 'Alcohol' }"
                      v-for="allergen in allergens"
                      :key="`allergen-${allergen.id}`">
                      <label :for="`allergen-${allergen.id}`">
                        <input
                          type="checkbox"
                          :id="`allergen-${allergen.id}`"
                          v-model="allergen.checked"
                          @input="setUnsavedChanges(true)" />

                        <p class="row">
                          {{ allergen.name }}
                        </p>
                      </label>
                      <span class="o18 row" v-if="allergen.name == 'Alcohol'">
                        18+
                        <SmallTipPopUp
                          text="Alcohol will be only served at least 18 years old." />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="dietaries">
                <div class="input-wrapper">
                  <label for="dietaries">Dietary</label>
                  <div class="input-wrapper_options row">
                    <label
                      class="checkbox-wrapper-unit"
                      v-for="diet in dietary"
                      :key="`dietary-${diet.code}`"
                      :for="`dietary-${diet.code}`">
                      <input
                        type="checkbox"
                        v-model="diet.checked"
                        name="dietary"
                        :value="diet.code"
                        :id="`dietary-${diet.code}`"
                        @input="setUnsavedChanges(true)" />
                      <p>
                        {{ diet.displayValue }}
                      </p>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="components mb_2">
              <label for="components">Components</label>
              <div class="components-wrapper">
                <ComponentGridByItem
                  :itemId="selectedItemProp.id"
                  :rowDataProps="componentGridRowData"
                  @editDetail="editComponentDetail"
                  @updateComponentsOrder="updateComponentsOrder" />
                <button
                  class="text-icon inline small add-from-library"
                  type="button"
                  :onclick="handleOpenComponentLibrary">
                  <Icon :path="mdiPlusMinusVariant" :size="18" />
                  <span class="text">From Library</span>
                </button>
              </div>
            </div>

            <div class="input-wrapper column">
              <label for="notes">Notes</label>
              <textarea
                name="notes"
                id="notes"
                cols="30"
                rows="10"
                placeholder="Add notes"
                v-model="selectedItemEdit.notes"
                @input="setUnsavedChanges(true)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <teleport to="body">
      <transition name="fade">
        <ModalDialog
          :maxWidth="'600'"
          :hideCloseButton="true"
          v-if="showModal === 'showAffectedOrders'">
          <template v-slot:title>Orders Affected</template>

          <ConfirmCancellation
            :affectedOrders="affectedOrders"
            @close="showModal = ''"
            @saveExistingItemConfirmed="saveExistingItemConfirmed">
            <template v-slot:message
              >This change has been determined as high risk. High risk changes
              include things such as adding allergens or changing dietary
              types.</template
            >
          </ConfirmCancellation>
        </ModalDialog>
      </transition>
    </teleport>
    <teleport to="body">
      <transition name="fade">
        <ModalDialog
          :maxWidth="'600'"
          className="no-title-modal"
          :hideCloseButton="true"
          v-if="showModal === 'editComponentDetail'">
          <div class="fixed-header row justify-space-between">
            <div class="input-wrapper row mb_0">
              <label for="">Component</label>
              {{ selectedComponent.name }}
            </div>
            <div class="row no-gap buttons-group">
              <ContainedButton
                :disabled="!isValidLevelConfiguration"
                @click="this.updateComponentMinDefaultMax"
                text="Update" />
              <TextButton
                text="Cancel"
                is-secondaryColor
                @click="() => (showModal = '')" />
            </div>
          </div>
          <div class="column no-gap component-setting">
            <div class="component-config">
              <div class="input-wrapper">
                <label for="type">Configuration</label>
                <div class="input-wrapper_options row">
                  <span v-if="!isValidLevelConfiguration"
                    >Not a valid configuration</span
                  >
                  <label
                    v-else
                    class="checkbox-wrapper-unit"
                    v-for="tag in levelConfigurationTags"
                    v-bind:key="tag"
                    :title="getLevelConfigurationTagTooltip(tag)">
                    <!-- Please make tooltip better, I rushed this -->
                    <p>{{ tag }}</p>
                  </label>
                </div>
              </div>

              <!-- <div
                class="input-wrapper"
                v-if="selectedComponent.unitType !== NONE">
                <label for="type">Per Portion</label>
                <div class="input-wrapper_options row">
                  <input
                    name="portion"
                    type="number"
                    min="0"
                    v-model="this.selectedComponent.unitAmount" />
                  <CustomDropDown
                    v-if="
                      selectedComponent.unitType === MASS ||
                      selectedComponent.unitType === VOLUME
                    "
                    :is-white="false"
                    :width="150"
                    :value="getSelectedUnitValue"
                    :options="getOptionsByType"
                    displayByKey="symbol"
                    @onChange="handleChangeUnit" />
                </div>
              </div> -->
            </div>

            <div class="input-wrapper row">
              <label for="minimum">Minimum</label>
              <input
                name="minimum"
                type="number"
                min="0"
                :class="{
                  invalid:
                    selectedComponentLevelDefault <
                    selectedComponentLevelMinimum,
                }"
                :max="selectedComponentLevelDefault"
                v-model="selectedComponentLevelMinimum" />
              <p
                v-if="
                  selectedComponentLevelDefault < selectedComponentLevelMinimum
                "
                class="error">
                Minimum should be equal or less than default
              </p>
            </div>
            <div class="input-wrapper row">
              <label for="default">Default</label>
              <input
                type="number"
                min="0"
                name="default"
                v-model="selectedComponentLevelDefault" />
            </div>
            <div class="row component-adding">
              <div class="input-wrapper row">
                <label for="maximum"> Maximum </label>
                <input
                  name="maximum"
                  type="number"
                  :class="{
                    invalid:
                      selectedComponentLevelDefault >
                      selectedComponentLevelMaximum,
                  }"
                  :min="selectedComponentLevelDefault"
                  v-model="selectedComponentLevelMaximum" />
              </div>
              <div class="input-wrapper row">
                <label for="price">Price to add</label>
                <div>
                  <input
                    :disabled="
                      this.selectedComponentLevelDefault >=
                      this.selectedComponentLevelMaximum
                    "
                    name="price"
                    type="text"
                    v-model="displaySelectedComponentPrice"
                    @blur="isComponentPriceInputFocus = false"
                    @focus="isComponentPriceInputFocus = true"
                    min="0" />
                </div>
              </div>
              <p
                v-if="
                  selectedComponentLevelDefault > selectedComponentLevelMaximum
                "
                class="error">
                Maximum should be equal or more than default
              </p>
            </div>
            <div class="input-wrapper row">
              <label for="minimum">Group</label>
              <input
                name="group"
                type="text"
                v-model="selectedComponentGroup"
                @change="setSelectedComponentGroup" />
            </div>
          </div>
        </ModalDialog>
      </transition>
    </teleport>
    <teleport to="body">
      <transition name="fade">
        <ModalDialog
          :maxWidth="'600'"
          :hideCloseButton="true"
          v-if="showModal === 'showComponentLibrary'">
          <AddComponentsToItem
            :activeComponentIds="activeComponentIds"
            @close="showModal = ''"
            @addComponents="addExistingComponentsToItem" />
        </ModalDialog>
      </transition>
    </teleport>
  </div>
</template>

<script>
  import store from "@/store";
  import { useToast } from "vue-toastification";
  import { mapGetters } from "vuex";
  import Enumerable from "linq";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import SmallTipPopUp from "@/components/SmallTipPopUp.vue";
  import ModalDialog from "@/components/Dialogs/ModalDialog";
  import ConfirmCancellation from "@/components/ConfirmCancellation";
  import ComponentGridByItem from "@/components/ComponentGridByItem.vue";
  import AddComponentsToItem from "@/components/AddComponentsToItem.vue";
  import BackToPageLink from "@/components/BackToPageLink.vue";
  import { mdiFood, mdiCheck, mdiPlusMinusVariant } from "@mdi/js";
  import UnsavedChangesMixin from "@/mixins/UnsavedChangesMixin";
  // // import CustomDropDown from "@/components/CustomDropDown";
  // import { NONE, MASS, VOLUME } from "@/constants/unitTypes.ts";
  import ContainedButton from "@/components/Buttons/ContainedButton.vue";
  import TextButton from "@/components/Buttons/TextButton.vue";

  export default {
    mixins: [UnsavedChangesMixin],
    data() {
      return {
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        selectedItem: this.selectedItemProp,
        selectedItemEdit: this.selectedItemProp,
        isNewItem: this.isNewItemProp,
        allergens: [],
        dietary: [],
        saving: false,
        actionName: "Loading",
        loading: true,
        showModal: "",
        selectedComponentLevelMinimum: {},
        selectedComponentLevelDefault: {},
        selectedComponentLevelMaximum: {},
        selectedComponentPrice: 0,
        selectedComponentGroup: "",
        isComponentPriceInputFocus: false,
        affectedOrders: [],
        updatedItem: this.selectedItemProp,
        selectedComponent: null,
        componentGridRowData: null,
        componentTypes: ["Fixed", "Removable", "Optional"],
        mdiFood,
        mdiCheck,
        mdiPlusMinusVariant,
        taxRates: [],
        isTaxNumber: null,
        // NONE,
        // MASS,
        // VOLUME,
        // units: [],
      };
    },
    components: {
      LoadingSpinner,
      SmallTipPopUp,
      ModalDialog,
      ConfirmCancellation,
      ComponentGridByItem,
      AddComponentsToItem,
      BackToPageLink,
      // CustomDropDown,
      ContainedButton,
      TextButton,
    },
    props: {
      selectedItemProp: {
        type: Object,
        default: null,
      },
      isNewItemProp: {
        type: Boolean,
        default: false,
      },
      fromMenuEditor: {
        type: Boolean,
        default: false,
      },
    },
    emits: ["changeView", "itemCreated", "close", "changesMade"],
    computed: {
      ...mapGetters({
        apiPrivateOutletById: "apiPrivateOutlets/getById",
        apiPrivateVendorById: "apiPrivateVendors/getById",
        apiPrivateComponentById: "apiPrivateComponents/getById",
        apiPublicAllergensGetAll: "apiPublicAllergens/getAll",
        apiPrivateComponentsByItemId: "apiPrivateComponents/getByItemId",
        apiPublicTaxRatesGetAll: "apiPublicTaxRates/getAll",
        getAllUnits: "units/getAll",
      }),
      activeComponentIds() {
        if (this.isNewItem) {
          return;
        } else {
          return this.selectedItemEdit.components.map((o) => o.id);
        }
      },
      isValidLevelConfiguration() {
        return (
          // Please look at this and do it better, proper form validation would be useful
          this.selectedComponentLevelMinimum !== "" &&
          this.selectedComponentLevelDefault !== "" &&
          this.selectedComponentLevelMaximum !== "" &&
          this.selectedComponentLevelMinimum <=
            this.selectedComponentLevelMaximum &&
          this.selectedComponentLevelDefault <=
            this.selectedComponentLevelMaximum &&
          this.selectedComponentLevelDefault >=
            this.selectedComponentLevelMinimum
        );
      },
      levelConfigurationTags() {
        let tags = [];

        if (this.selectedComponentLevelDefault > 0) {
          tags.push("Included");
        }

        if (
          this.selectedComponentLevelDefault ===
            this.selectedComponentLevelMinimum &&
          this.selectedComponentLevelDefault ===
            this.selectedComponentLevelMaximum
        ) {
          tags.push("Fixed");
        }

        if (
          this.selectedComponentLevelDefault >
          this.selectedComponentLevelMinimum
        ) {
          tags.push("Reducible");
        }

        if (
          this.selectedComponentLevelMinimum == 0 &&
          this.selectedComponentLevelDefault >
            this.selectedComponentLevelMinimum
        ) {
          tags.push("Removable");
        }

        if (
          this.selectedComponentLevelDefault <
          this.selectedComponentLevelMaximum
        ) {
          tags.push("Increasable");
        }

        return tags;
      },
      displaySelectedComponentPrice: {
        get: function () {
          if (this.isComponentPriceInputFocus) {
            return this.selectedComponentPrice.toString();
          } else {
            return this.formatMoney(this.selectedComponentPrice);
          }
        },
        set: function (updatedValue) {
          let newValue = parseFloat(updatedValue.replace(/[^\d]\./g, ""));
          if (isNaN(newValue)) {
            newValue = 0;
          }
          this.selectedComponentPrice = newValue;
        },
      },
      // getOptionsByType() {
      //   const type = this.selectedComponent.unitType;
      //   const options = this.getAllUnits.filter((unit) => unit.unitType === type);
      //   return options;
      // },
      // getSelectedUnitValue() {
      //   const { unit } = this.selectedComponent;
      //   if (!unit || unit === NONE) {
      //     return this.getOptionsByType[0].symbol;
      //   } else {
      //     const selectedUnit = this.getOptionsByType.filter(u => u.identifier === unit)[0];
      //     return selectedUnit.symbol;
      //   }
      // }
    },
    methods: {
      async updateLocalVars() {
        this.selectedItemEdit = Object.assign({}, this.selectedItemProp);

        await this.apiPublicAllergensGetAll().then((response) => {
          this.allergens = response;

          if (!this.newItem && this.selectedItemEdit.allergens?.length) {
            this.allergens.forEach((allergen) => {
              if (this.selectedItemEdit.allergens.includes(allergen.id)) {
                allergen.checked = true;
              }
            });
          }
          this.loading = false;
        });

        if (this.isTaxNumber) await this.fetchTaxRates();

        store.state.apiPrivate.client.endpoints.enums
          .getDietaryTypeFlags()
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }

            return Promise.reject("Failed to download dietary information.");
          })
          .then((data) => {
            this.dietary = [];

            data.forEach((diet) => {
              if (!this.isNewItem && this.selectedItem.dietary) {
                diet.checked = this.selectedItem.dietary.includes(
                  diet.identifier
                );
              }

              this.dietary.push(diet);
            });
          });
      },
      async fetchTaxRates() {
        await this.apiPublicTaxRatesGetAll().then(
          (response) => (this.taxRates = response)
        );
      },
      getLevelConfigurationTagTooltip(level) {
        if (level === "Included") {
          return "Configured to always include this component, for example - a key ingredient needed to make an item.";
        }

        if (level === "Fixed") {
          return "Configured to make this component fixed and non-alterable, i.e. a customer cannot alter the quantity of this component on their order.";
        }

        if (level === "Reducible") {
          return "Configured to make this component reducible on an item, i.e. a customer can reduce the amount of this on their order. This does not ALWAYS mean this is removable.";
        }

        if (level === "Removable") {
          return "Configured to make this component removable, i.e. a customer can remove this component.";
        }

        if (level === "Increasable") {
          return "Configured to make this component increasable, i.e. a customer can increase the quantity of this component.";
        }
      },
      // Clicked save button, check if we're editing existing or creating new item.
      saveItem() {
        if (!this.selectedItemEdit.name) {
          useToast().error("Name is a required field");
          return;
        }

        this.saving = true;

        this.selectedItemEdit.allergens = Enumerable.from(this.allergens)
          .where((x) => x.checked)
          .select((x) => x.id)
          .toArray();

        this.selectedItemEdit.dietary = Enumerable.from(this.dietary)
          .where((x) => x.checked)
          .select((x) => x.identifier)
          .toArray();

        const save = this.isNewItem ? this.saveNewItem : this.saveExistingItem;
        save();
      },

      saveAsNewItem() {
        if (!this.selectedItemEdit.name) {
          useToast().error("Name is a required field");
          return;
        }

        this.saving = true;

        this.selectedItemEdit.allergens = Enumerable.from(this.allergens)
          .where((x) => x.checked)
          .select((x) => x.id)
          .toArray();

        this.selectedItemEdit.dietary = Enumerable.from(this.dietary)
          .where((x) => x.checked)
          .select((x) => x.identifier)
          .toArray();

        this.saveNewItem();
      },

      // Save edits to an existing item
      saveExistingItem() {
        return store.state.apiPrivate.client.endpoints.items
          .update(this.selectedItemEdit, this.selectedItemEdit.id)
          .then((response) => {
            if (response.status == 200) {
              this.setUnsavedChanges(false);

              if (this.fromMenuEditor) {
                //this.$emit("close", true);
                this.$emit("changesMade", this.selectedItemEdit);
              }

              store.dispatch("apiPrivateItems/upsert", this.selectedItemEdit);
              useToast().success("Item saved.");
            } else if (response.status == 409) {
              this.handleItemSaveConflict(response.data);
            } else {
              useToast().error("Failed to save item. Please contact Support.");
            }
            this.saving = false;
          });
      },

      saveExistingItemConfirmed() {
        return store.state.apiPrivate.client.endpoints.items
          .update(
            this.selectedItemEdit,
            this.selectedItemEdit.id,
            "affected-orders=cancel"
          )
          .then((response) => {
            if (response.status == 200) {
              this.setUnsavedChanges(false);
              this.$emit("changesMade", this.selectedItemEdit);
              store.dispatch("apiPrivateItems/upsert", this.selectedItemEdit);

              useToast().success("Affected Orders cancelled and item saved.");
            } else {
              useToast().error("Failed to save item. Please contact Support.");
            }
            this.saving = false;
            // this.$emit("close");
          });
      },

      handleItemSaveConflict(payload) {
        useToast().warning("Could not save item due to existing orders.");

        this.showModal = "showAffectedOrders";
        this.affectedOrders = payload.meta["affected-orders"].$values;
      },

      // Create a new item
      saveNewItem() {
        return store.state.apiPrivate.client.endpoints.outletItems
          .create(this.selectedItemEdit, this.operatorId)
          .then((response) => {
            if (response.status == 201) {
              this.selectedItemEdit.id = response.data.data.id;
              this.isNewItem = false;
              this.unsavedChanges = this.fromMenuEditor
                ? this.setUnsavedChanges(true)
                : this.setUnsavedChanges(false);
              this.selectedItem = Object.assign({}, this.selectedItemEdit);
              useToast().success("Item Created.");
              this.$emit("itemCreated", this.selectedItemEdit);
              if (this.fromMenuEditor) this.$emit("close", false);
            } else {
              useToast().error(
                "Failed to create item. Please contact Support."
              );
            }
            this.saving = false;
          });
      },

      editComponentDetail(row) {
        this.showModal = "editComponentDetail";
        this.selectedComponent = row.data;

        this.selectedComponentLevelMinimum =
          this.selectedComponent.levels.minimum;
        this.selectedComponentLevelDefault =
          this.selectedComponent.levels.default;
        this.selectedComponentLevelMaximum =
          this.selectedComponent.levels.maximum;
        this.selectedComponentPrice = this.selectedComponent.priceToAdd;
        this.selectedComponentGroup = this.selectedComponent.groupName;
      },

      handleComponentMinDefaultMaxChange(input) {
        input;
        // We should apply some front-end validation to the inputs here,
        // basic stuff you know -
        //this.selectedComponent.levels[input.name] = Number(input.value);
      },

      handleComponentTypeChange(input) {
        let types = this.selectedComponent?.type || [];
        if (input.checked) {
          types.push(input.value);
        } else {
          const index = types.indexOf(input.value);
          types.splice(index, 1);
        }
        this.selectedComponent.type = types;
      },

      addExistingComponentsToItem(components) {
        const newComponentIds = components.map(({ id }) => id);

        this.updateComponents(newComponentIds, {
          getComponentDataFromAPI: false,
        });
      },

      updateComponentMinDefaultMax() {
        this.setUnsavedChanges(true);

        this.selectedComponent.levels.minimum =
          this.selectedComponentLevelMinimum;

        this.selectedComponent.levels.default =
          this.selectedComponentLevelDefault;

        this.selectedComponent.levels.maximum =
          this.selectedComponentLevelMaximum;

        this.selectedComponent.groupName = this.selectedComponentGroup;

        if (
          this.selectedComponentLevelDefault ===
          this.selectedComponentLevelMaximum
        ) {
          this.selectedComponent.priceToAdd = 0;
        } else {
          this.selectedComponent.priceToAdd = this.selectedComponentPrice;
        }

        this.selectedComponent.tags = this.levelConfigurationTags;

        const componentIds = this.selectedItemEdit.components.map(
          ({ id }) => id
        );

        const index = componentIds.indexOf(this.selectedComponent.id);
        let newComponents = [...this.selectedItemEdit.components];

        newComponents[index] = this.selectedComponent;
        this.componentGridRowData = newComponents;

        this.showModal = "";
      },

      handleOpenComponentLibrary() {
        this.showModal = "showComponentLibrary";
      },

      updateComponentsOrder(ids) {
        this.setUnsavedChanges(true);

        const currentItemComponentIds = this.selectedItemEdit.components.map(
          ({ id }) => id
        );

        let orderedComponents = [];
        ids.forEach((id) => {
          orderedComponents.push(
            this.selectedItemEdit.components[
              currentItemComponentIds.indexOf(id)
            ]
          );
        });
        this.selectedItemEdit.components = orderedComponents;
      },

      async updateComponents(ids, getComponentDataFromAPI = false) {
        this.setUnsavedChanges(true);

        const currentItemComponentIds = this.selectedItemEdit.components.map(
          ({ id }) => id
        );

        // duplicate components array
        let newComponents = this.selectedItemEdit.components.slice();
        const promises = [];

        // form the component that does not exist in the current components
        for (const id of ids) {
          const ifIdExistInCurrentItemComponents =
            currentItemComponentIds.includes(id);

          if (!ifIdExistInCurrentItemComponents) {
            let newComponentInitialData = (id, data) => ({
              id,
              levels: { minimum: 1, default: 1, maximum: 1 },
              tags: ["Included", "Fixed"],
              priceToAdd: 0,
              ...data,
            });

            if (getComponentDataFromAPI) {
              promises.push(
                this.apiPrivateComponentById(id).then((component) => {
                  newComponents.push(newComponentInitialData(id, component));
                })
              );
            } else {
              newComponents.push(newComponentInitialData(id));
            }
          }
        }

        // filter out the removed components
        newComponents = newComponents.filter(({ id }) => ids.includes(id));

        if (getComponentDataFromAPI) await Promise.all(promises);

        this.selectedItemEdit.components = newComponents;
        this.componentGridRowData = newComponents;
      },

      async getTaxNumber() {
        let outlet = await this.apiPrivateOutletById(this.operatorId);
        this.isTaxNumber = outlet.taxNumber;
      },

      setSelectedComponentGroup(event) {
        this.selectedComponentGroup = event.target.value.trim();
      },

      handleChangeUnit(newValue) {
        this.selectedComponent.unit = newValue.identifier;
      },
    },
    async mounted() {
      await this.getTaxNumber();
      this.updateLocalVars();
      this.$store.dispatch("units/getUnits");

      if (this.isNewItem) {
        return;
      } else {
        await this.apiPrivateComponentsByItemId(this.selectedItemEdit.id).then(
          (data) => {
            this.componentGridRowData = data;
            this.selectedItemEdit.components = data;
          }
        );
      }
    },
  };
</script>

<style lang="scss" scoped>
  .fixed-header {
    @include themed() {
      color: t($text);
      background: t($back);
    }

    display: flex;
    z-index: 2;
    gap: 1rem;

    position: sticky;
    top: calc($topbar_height - 1px);
    right: initial;

    width: calc(100% + 20px);
    max-width: 100%;
    margin-top: -30px;
    padding: 20px 10px 1rem;
    border-bottom: 1px solid $col_faded;

    input {
      margin-bottom: 0;

      &:disabled {
        background: none;
        border-radius: 0;
        padding: 0;
        margin: 0;
      }
    }

    .name,
    .actions {
      gap: 1rem;
    }

    .actions {
      button {
        .mobile-layout & {
          font-size: 0.8rem;
          justify-content: stretch;
        }
      }

      @media screen and (max-width: 1279px) {
        font-size: 0.8rem;
        gap: 0;
      }

      @media screen and (max-width: 990px) {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        background: #fff;
        box-shadow: $fixed_button_box_shadow;
        border-radius: $card_radius;
        z-index: 100;
        padding: 5px;
        gap: 5px;
      }

      @media screen and (max-width: 660px) {
        position: fixed;
        bottom: 1rem;
        right: 1rem;
        background: #fff;
        box-shadow: $fixed_button_box_shadow;
        border-radius: $card_radius;
        z-index: 100;
        padding: 5px;
        gap: 5px;
      }
    }

    .name {
      flex-wrap: nowrap;

      .app-container.mobile-layout & {
        width: 100%;
      }

      &:disabled {
        font-weight: bold;
        font-size: 1.5em;
      }
    }
  }

  // for Editor opened in Modal, like editing menu item
  .modal-container {
    .fixed-header {
      top: -1rem;

      .back-icon {
        display: none;
      }
    }
  }

  .content-wrapper {
    margin-top: 2rem;
  }

  .input-wrapper {
    margin-bottom: 1rem;

    p,
    input {
      margin: 0;
    }

    label {
      min-width: 100px;
      flex-shrink: 0;
    }

    input[name="name"] {
      max-width: 560px;
      min-width: 140px;
      width: calc(100% - 280px);
    }

    input[name="price"] {
      width: 100px;

      @media screen and (max-width: 768px) and (min-width: 480px) {
        width: 80px;
      }
    }

    input[type="checkbox"] {
      margin: 0;
      padding: 0;
    }

    &_options {
      gap: 20px;
      flex-wrap: wrap;

      label {
        font-weight: initial;
        margin-bottom: 0 !important;
        width: max-content;
      }
    }

    textarea {
      margin-bottom: 0;
    }
  }

  .complexity option {
    color: $col_softblack;
  }

  .dietaries > .row,
  .allergens > .row {
    align-items: flex-start;

    @media screen and (max-width: 1280px) {
      .panel-expanded & {
        flex-direction: column;
        align-items: flex-start;

        label {
          margin-bottom: 1rem;
        }
      }
    }
  }

  .allergens {
    .input-wrapper_options {
      gap: 5px;
    }

    label[for="allergens"] {
      margin-bottom: 1rem;
    }

    .over18 {
      background: $col_alpha-lightest;

      .o18 {
        color: $col_delta;
        font-weight: 600;
        gap: 3px;
        padding-left: 0.5rem;

        .material-icons {
          font-size: 0.875rem;
          font-weight: normal;
          color: $col_alpha_darker;
        }
      }
    }
  }

  .dietaries {
    .input-wrapper_options {
      gap: 5px;
    }

    label[for="dietaries"] {
      margin-bottom: 1rem;
    }
  }

  .components {
    .input-wrapper_options {
      gap: 5px;
    }

    label[for="components"] {
      margin-bottom: 1rem;
      display: block;
      font-weight: bold;
    }
  }

  label[for="name"] {
    font-weight: bold;
    width: 100px;
    font-size: 0.875rem;
  }

  @media screen and (max-width: 425px) {
    .header {
      height: 104px;

      .input-wrapper {
        margin-bottom: 0;
      }
    }

    .input-wrapper.row {
      flex-direction: column !important;
      flex-wrap: wrap;
      align-items: baseline;

      input,
      textarea,
      select {
        width: 100%;
      }
    }
  }

  input[name="minimum"],
  input[name="maximum"],
  input[name="default"] {
    width: 100px;

    @media screen and (max-width: 768px) and (min-width: 480px) {
      width: 80px;
    }
  }

  .components-wrapper {
    background: #f8f8f8 !important;
    border-radius: $card_radius;
  }

  .component-setting {
    min-height: 400px;

    .component-config {
      margin-top: 1rem;
      margin-bottom: 1rem;
      @include flex-initial($direction: column);

      label {
        cursor: default;
      }

      .input-wrapper {
        @include flex-initial($direction: column);
        margin-bottom: 0.5rem;
      }

      .input-wrapper_options {
        gap: 0.5rem;
      }
    }

    input.invalid {
      border-color: $col_alpha;
    }

    .component-adding {
      gap: 0.5rem 1rem;
    }

    p.error {
      color: $col_alpha;
      font-size: 0.875rem;
      margin-top: 0;
    }
  }

  button.add-from-library {
    margin: 0.25rem 0 1rem 0.5rem;
  }

  .modal-wrapper {
    .fixed-header {
      right: 0;
      margin-left: -1rem;
      max-width: unset;
      margin-right: -1rem;
      width: calc(100% + 2rem);
      padding: 1rem;
    }
  }
</style>
