export const formatMoneyCell = (value, formatMoney) => {
  if (value == null) {
    return "-";
  }

  if (value == 0) {
    return "<i><small>Free</small></i>";
  }

  return formatMoney(value);
};
