<template>
  <div>
    <div v-if="allVendorComponents" class="table-wrapper">
      <div class="header row justify-space-between">
        <h4>Add Components From Library</h4>
        <div class="row no-gap">
          <button
            class="contained save-btn"
            :disabled="updating"
            @click="updateComponents">
            <span class="icon">
              <Icon :path="mdiCheck" :size="20" />
            </span>
            <span class="text">Update</span>
            <LoadingSpinner v-if="updating" />
          </button>
          <button class="text" @click="this.$emit('close')">
            <span class="text">Cancel</span>
          </button>
        </div>
      </div>
      <div class="content-wrapper">
        <table class="table">
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="component in allVendorComponents" :key="component.id">
              <td class="checkbox">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  v-model="component.checked" />
              </td>
              <td class="name">{{ component.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import LoadingSpinner from "./LoadingSpinner.vue";
  import { mdiCheck } from "@mdi/js";

  export default {
    data() {
      return {
        operatorId: parseInt(this.$route.params.operatorId),
        allVendorComponents: null,
        activeSection: null,
        filteredList: null,
        activeItemIds: [],
        updating: false,
        mdiCheck,
      };
    },
    props: {
      activeComponentIds: {
        type: Object,
      },
    },
    emits: ["close", "addComponents"],
    computed: {
      ...mapGetters({
        apiPrivateComponentsByOutletId: "apiPrivateComponents/getByOutletId",
      }),
    },
    methods: {
      updateComponents() {
        this.updating = true;
        // add this back to item component table
        const checkedComponent = this.allVendorComponents.filter(
          ({ checked }) => checked
        );
        this.$emit("addComponents", checkedComponent);
        this.$emit("close");
      },
    },
    beforeMount() {
      const outletId = this.operatorId;

      // get all components available for this vendor
      this.apiPrivateComponentsByOutletId(outletId).then((data) => {
        this.allVendorComponents = data;
        // Determine which components are already in this section, so we can "check" the checkoboxes
        this.allVendorComponents.forEach((component) => {
          if (this.activeComponentIds?.includes(component.id)) {
            component.checked = true;
          } else {
            component.checked = false;
          }
        });
      });
    },
    components: { LoadingSpinner },
  };
</script>

<style lang="scss" scoped>
  .header {
    position: sticky;
    margin-top: -1rem;
    top: -1rem;
    width: 100%;
    height: 70px;
    background: #fff;
    display: flex;
    align-self: center;
    padding: 1rem 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 1rem;

    h4 {
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .content-wrapper {
    height: $modal_content_wrapper_height;
    overflow: auto;
  }

  .table-wrapper {
    margin-bottom: 1rem;
    @media screen and (max-width: 480px) {
      height: 400px;
    }

    @media screen and (max-width: 375px) {
      height: 320px;
    }
  }

  table,
  th,
  td {
    border: none !important;
    padding: 0 !important;
  }

  table tr:nth-child(2n) td {
    background: $col_beta-lightest;
  }

  td {
    vertical-align: middle;
    padding: 0.5rem 0.7rem !important;
  }

  td.name {
    font-size: 1rem;
    line-height: 1.3em;
    font-size: 0.875rem;
  }

  td.checkbox {
    width: 40px;
  }

  td.price {
    width: 100px;
  }

  input[type="checkbox"] {
    width: 15px !important;
    height: 15px !important;
    margin: 0 !important;
  }

  span.price {
    &::before {
      display: inline-block;
      padding-right: 5px;
    }
  }

  thead tr th {
    font-size: 0.875rem;
    color: #aaa;
    font-weight: normal;
    padding: 0.75rem !important;
    background: #fff !important;
  }
</style>
