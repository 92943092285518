<template>
  <div class="centered-loading-wrapper column" v-if="loading">
    <LoadingSpinner />
    <p>Loading Components...</p>
  </div>
  <div v-else>
    <ag-grid-vue
      class="ag-theme-alpine component"
      @grid-ready="onGridReady"
      style="width: 100%; height: 100%"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :rowDragManaged="true"
      :rowDragEntireRow="true"
      animateRows="true"
      @cellClicked="onCellClicked"
      @rowClicked="onCellClicked"
      @dragStopped="getIdsByRowIndex"
      :paginationAutoPageSize="true"
      :pagination="true"
      domLayout="autoHeight">
    </ag-grid-vue>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import { AgGridVue } from "ag-grid-vue3";
  //import { stringToClassName } from "@/helpers/stringFormatter.js";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import { formatMoneyCell } from "@/helpers/gridCellRenderers.js";
  // import { NONE, EACH } from "@/constants/unitTypes.ts";
  import ArrayChips from "./AggridComponents/ArrayChips.vue";

  export default {
    data() {
      return {
        columnDefs: [],
        defaultColDef: null,
        gridApi: null,
        columnApi: null,
        rowData: [],
        clickedRow: null,
        allAllergens: [],
        loading: true,
      };
    },
    props: {
      itemId: {
        type: Number,
      },
      rowDataProps: {
        type: Array,
      },
    },
    computed: {
      ...mapGetters({
        apiPrivateComponentsByItemId: "apiPrivateComponents/getByItemId",
        apiPrivateComponentsDeleteById: "apiPrivateComponents/deleteById",
        apiPublicAllergensGetAll: "apiPublicAllergens/getAll",
        getAllUnits: "units/getAll",
      }),
    },
    watch: {
      rowDataProps: function () {
        this.rowData = this.rowDataProps;
      },
    },
    emits: ["changeView", "updateComponentsOrder", "editDetail"],
    methods: {
      onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.hideOverlay();
      },

      onCellClicked(row) {
        this.clickedRow = row;
        this.$emit("editDetail", row);
      },

      getIdsByRowIndex() {
        const nodesWithIndexAndIds = [];

        this.gridApi.forEachNode((node) => {
          nodesWithIndexAndIds.push({ index: node.rowIndex, id: node.data.id });
        });

        this.$emit(
          "updateComponentsOrder",
          nodesWithIndexAndIds.map((obj) => obj.id)
        );
      },

      async getAllAllergens() {
        await this.apiPublicAllergensGetAll().then((response) => {
          this.allAllergens = response;
        });
      },

      setGridOptions() {
        this.defaultColDef = {
          suppressMenu: true,
          suppressMovable: true,
          floatingFilter: true,
          resizable: true,
          filter: true,
          sortable: true,
          minWidth: 100,
        };

        this.columnDefs = [
          /* eslint-disable vue/no-unused-components */
          {
            field: "id",
            hide: true,
            headerName: "",
            rowDrag: true,
          },

          {
            field: "name",
            flex: 2,
            pinned: "left",
          },

          // {
          //   field: "unit",
          //   headerName: "Per Portion",
          //   minWidth: 120,
          //   cellRenderer: ({ data }) => {
          //     const { unitAmount, unitType, unit } = data;

          //     if (unitType === NONE ) return NONE;
          //     if( unitType === EACH)   return unitAmount;

          //     const symbol = this.getAllUnits.filter( u => u.identifier === unit )[0].symbol;
          //     return unitAmount + " " + symbol;
          //   },
          // },

          {
            field: "tags",
            headerName: "Configuration",
            flex: 2,
            minWidth: 180,
            cellRenderer: "ArrayChips",
          },

          {
            field: "priceToAdd",
            headerName: "Price",
            flex: 1,
            cellRenderer: ({ value }) =>
              formatMoneyCell(value, this.formatMoney),
          },
          {
            field: "allergens",
            flex: 1,
            valueGetter: ({ data }) =>
              this.allAllergens
                .filter(({ id }) => data.allergens?.includes(id))
                .map(({ name }) => name),
            cellRenderer: "ArrayChips",
          },
          {
            field: "dietaryType",
            flex: 2,
            minWidth: 180,
            cellRenderer: "ArrayChips",
          },
          {
            field: "groupName",
            headerName: "Group",
          },
        ];
        //this.gridApi.hideOverlay();
      },
    },
    async beforeMount() {
      await this.getAllAllergens();
      this.setGridOptions();
      this.rowData = this.rowDataProps;
      this.loading = false;
    },
    components: {
      AgGridVue,
      LoadingSpinner,
      ArrayChips,
    },
  };
</script>

<style lang="scss">
  .ag-theme-alpine {
    margin-top: 1rem;
    height: auto;
  }
</style>
